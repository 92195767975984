





























































import Vue from 'vue';
import axios from 'axios';

import GeneFacets from '@/facets/GeneFacets';
import Facet from '@/models/Facet';
import SearchableGene from '@/models/SearchableGene';

import { IGeneInfo } from '@/interfaces/IGene';

import FacetList from '@/components/FacetList.vue';
import GeneCard from '@/components/GeneCard.vue';
import Ideogram from '@/components/Ideogram.vue';
import SearchResultsToolbar from '@/components/SearchResultsToolbar.vue';
import Sidepanel from '@/components/Sidepanel.vue';

export default Vue.extend({
  created() {
    this.$trackEvent('gene_view', {
      gene_id: this.$route.params.id,
    });
    this.tab = this.facets.findIndex((f: Facet) => f.name === this.$route.params.tab);
  },
  components: {
    FacetList,
    GeneCard,
    Ideogram,
    SearchResultsToolbar,
    Sidepanel,
  },
  data: () => ({
    facets: GeneFacets,
    geneInfo: {} as IGeneInfo,
    tab: 0,
  }),
  methods: {
    async getCurrentGene(id: string) {
      const apiUrl = `${this.$apiPrefix}/genes/${id}/info/`;
      try {
        const response = await axios.get(apiUrl);
        return response.data;
      } catch (err) {
        return null;
      }
    },
    updateTab(index: number): void {
      this.tab = index;
      this.$router.push({
        name: 'GeneSearchResults',
        params: {
          tab: this.facets[this.tab].name,
        },
      });
    },
  },
  async mounted() {
    this.$store.commit('sidepanel/close');
    this.geneInfo = await this.getCurrentGene(this.$route.params.id);
    this.$store.commit('setEntity', {
      selectedSearchableEntity: new SearchableGene({
        gene: this.geneInfo.gene,
        is_canonical: true,
        matching_symbol: this.geneInfo.gene.canonical_symbol,
      }),
    });
  },
});
