




import Vue from 'vue';
import Ideogram from 'ideogram';

export default Vue.extend({
  data: () => ({
    ideogram: {},
  }),
  mounted() {
    const config = {
      container: '#ideogram-id',
      chromosome: this.chromosome,
      organism: 'human',
      assembly: 'GRCh38',
      rotatable: false,
      chrLabelSize: 12,
      fontFamily: 'Open Sans, sans-serif',
      annotations: [{
        name: this.symbol,
        chr: this.chromosome,
        start: this.start,
        stop: this.end,
      }],
    };
    this.ideogram = new Ideogram(config);
  },
  props: {
    chromosome: String,
    start: Number,
    end: Number,
    symbol: String,
  },
});
