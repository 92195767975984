





































import Vue from 'vue';
import { IGeneInfo } from '@/interfaces/IGene';
import { IExternalSource } from '@/interfaces/IExternalSource';
import ExternalSource from './ExternalSource.vue';
import MoreLess from './MoreLess.vue';

export default Vue.extend({
  components: {
    ExternalSource,
    MoreLess,
  },
  computed: {
    ensemblUrl(): string {
      return `https://ensembl.org/id/${this.geneInfo.gene.ensembl_id}`;
    },
    entrezUrl(): string {
      return `https://www.ncbi.nlm.nih.gov/gene/${this.geneInfo.gene.entrez_id}`;
    },
    ucscUrl(): string | null {
      if (this.geneInfo.genome_region) {
        return 'https://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&'.concat(
          `position=${this.geneInfo.genome_region.chromosome}:${this.geneInfo.genome_region.start}-${this.geneInfo.genome_region.end}`,
        );
      }
      return null;
    },
    ncbiBrowserUrl(): string {
      return `https://www.ncbi.nlm.nih.gov/genome/gdv/browser/?context=gene&acc=${this.geneInfo.gene.entrez_id}`;
    },
    civicUrl(): string {
      return `https://civicdb.org/links/entrez_id/${this.geneInfo.gene.entrez_id}`;
    },
    cosmicUrl(): string {
      return `https://cancer.sanger.ac.uk/cosmic/gene/analysis?ln=${this.geneInfo.gene.canonical_symbol}`;
    },
    genomeBrowserSources(): IExternalSource[] {
      return [
        { href: this.ncbiBrowserUrl, title: 'NCBI' },
        { href: this.ucscUrl, title: 'UCSC' },
      ].filter((source: IExternalSource) => source.href);
    },
  },
  props: {
    geneInfo: Object as () => IGeneInfo,
  },
  metaInfo() {
    const geneInfo = this.$props.geneInfo;
    let description;
    if (geneInfo.summary) {
      description = geneInfo.summary.text;
    } else {
      description = `${geneInfo.gene.canonical_symbol}: ${geneInfo.gene.name}`;
    }
    if (description.length > 160) {
      description = description.substring(0, 157).concat('...');
    }
    return {
      meta: [{ name: 'description', content: description }],
    };
  },
});
